$greyblue: #62aed6;
$babyblue: #f0fdff;
$reddish: #c73a3a;
$white: #f8f8f8;
$secondwhite: #f5f5f5;
$greyish: #b8b8b8;
$purplishbrown: #3e3039;
$lightgrey: #afafaf;
$darkgrey: #707070;
$darkaquamarine: #00897B;
$lightaquamarine: #00897B;
$pink: #fde4e4;