@import '../../assets/styles/colors';

.button{
    width: 136px;
    height: 45px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 900;
    border: none;
    border-radius: 5px;
    letter-spacing: normal;
}

.primary{
    background: $greyblue;
    color: $white;
}

.secondary{
    background: $white;
    color: $lightgrey;
    border: 0px solid $greyblue;
}

.outline-secondary{
    background: $white;
    color: $greyblue;
    border: 1px solid $greyblue;
}

.button:hover{
    filter: brightness(105%);
}