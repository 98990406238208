@import '../../assets/styles/colors';

.header-owner{
    background: $darkaquamarine;
    // height: 60px
}

.header-owner a{
  color: #fff !important;
  font-family: Roboto;
}

.subheader{
  background: $lightaquamarine;
  // height: 250px;
}

.add-student-form{
  padding: 50px 100px 50px 100px
}