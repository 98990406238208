.list{
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;
}

.list li{
    font-family: Roboto;
    font-size: 12px;
    margin-top: 5px
}

.url-warning{
    width: 100%;
    background: #fdfdfd;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 12px 10px 10px 12px;
    height: 50px;
}

.gray-text{
    font-family: Roboto;
    color: '#afafaf';
    font-size: 14px;
    margin-top: 5;
    margin-bottom: 5;
}

.Please-wait-were-creating-your-online-studio {
    width: 349px;
    height: 74px;
    margin: 0 0 36px;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
  }
