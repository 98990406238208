@import "../../assets/styles/colors";

.b-dashed {
  border: 1.5px dashed $greyblue;
  border-radius: 15px;
}

.b-gray {
  border: 1px solid $lightgrey;
}

.rounded-lg {
  border-radius: 10px !important;
}

.lightaquamarine {
  color: $lightaquamarine;
}

.bg-babyblue {
  background-color: $babyblue;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  /* border-top: 0.3em solid transparent; */
  /* border-right: 0; */
  /* border-bottom: 0.3em solid transparent; */
  /* border-left: 0.3em solid; */
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  /* border-top: 0.3em solid; */
  /* border-right: 0.3em solid transparent; */
  /* border-bottom: 0; */
  /* border-left: 0.3em solid transparent; */
}

.primary.dropdown-toggle {
  color: #fff;
  background-color: #62aed6;
  border-color: #0a53be;
}
