.login-left-image {
  background: url(./../../assets/images/login_image.jpg);
  background-size: cover;
  width: 100%;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 215px;
}

.swift-logo {
  width: 150px;
}

.studio-button {
  min-width: 60%;
  height: 63px;
  // padding: 22px 34px 22px;
  border-radius: 10px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  margin-top: 10px;
}

.studio-button:hover {
  border: solid 2px var(--greyblue);
  background-color: #4c9ac3;
  color: #fff;
}

.facebook-btn {
  height: 45px;
  font-family: "Roboto";
  border: none;
  border-radius: 5px;
  letter-spacing: normal;
  width: 215px;
  background-color: #4267b2;
  font-weight: normal;
  font-size: 12px !important;
  color: #fff;
}

.google-btn {
    height: 45px;
    font-family: "Roboto";
    border: none;
    border-radius: 5px;
    letter-spacing: normal;
    width: 215px;
    background-color: #ea4335;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #fff;
  }
