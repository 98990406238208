@import '../../assets/styles/colors';

.form-group{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.text-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid $darkgrey;
    padding-bottom: 5px;
    margin-top: 5px;
    font-family: Roboto;
    font-size: 14px;
    color: #000;
    outline: none;    
}