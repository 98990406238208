@import '../../assets/styles/colors';

.form-group{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.select-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid $darkgrey;    
    background-color: #fff;
    font-family: Roboto;
    font-size: 14px;
    color: #000;
    outline: none;
}