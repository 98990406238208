@import "../../../assets/styles/colors";

.b-gray {
  border: 1px solid $lightgrey;
}

.b-greyblue {
  border: 1px solid $greyblue;
}

.Rectangle-15 {
  width: 14rem;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.text-lightaquamarine {
  color: $lightaquamarine;
}

.bg-lightaquamarine {
  background-color: $lightaquamarine;
}

.custom-button {
  border: none;
  background-color: $lightaquamarine;
  color: white;
}

.custom-button:hover {
  filter: brightness(105%);
}

.custom-button1 {
  cursor: pointer;
  background-color: white;
  color: $greyblue;
  border: 1px solid $greyblue;
}

.custom-button1:hover {
  background-color: $greyblue;
  color: white;
}

.custom-button2 {
  cursor: pointer;
  background-color: $greyblue;
  color: white;
  // border: 1px solid $greyblue;
}

.custom-button2:hover {
  filter: brightness(105%);
}

.custom-button3 {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid $greyblue;
}

.custom-button3:hover {
  background-color: $greyblue;
  color: white;
}

.bg-pink {
  background-color: $pink;
}

.rounded-10 {
  border-radius: 10px;
}

.react-calendar__tile--active {
  background-color: $greyblue !important;
}

.bg-greyblue {
  background-color: $greyblue;
}

.greyblue {
  color: $greyblue !important;
}

.text-pink {
  color: rgb(184, 111, 156);
}

.bg-registered {
  background-color: #b9e6bc;
}

.bg-waitlist {
  background-color: #ffff95;
}

.bg-pending {
  background-color: #ff9595;
}
.bg-cancel {
  background-color: #f0f0f0;
}

.custom-hover:hover {
  opacity: 0.5;
}

.custom-hover2 {
  color: $greyblue;
}

.custom-hover2:hover {
  background-color: $greyblue;
  color: white;
}

.custom-hover3:hover {
  background-color: $greyblue;
  color: white;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.custom-hover4 {
  background-color: white;
}

.custom-hover4:hover {
  background-color: $greyblue;
  color: white;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.custom-hover5 {
  background-color: white;
}

.custom-hover5:hover {
  background-color: $greyblue;
  color: white;
  transition: all;
  transition-duration: 700ms;
}

.custom-hover5:hover button {
  background-color: white;
  color: $greyblue !important;
}
